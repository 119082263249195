<template>
  <div class="bank-account">
    <h2 class="h2">
      {{$t('paymentDataTitle')}}
      <TooltipIcon
        :title="$t('personalInformationTipTitle')"
        :text="$t('personalInformationTipText')"
      />
    </h2>

    <div class="user-profile--notification user-profile--notification__processing">
      <div class="user-profile--notification-text">
        <div class="text-16-700">
          {{ $t('paymentMessage') }}
        </div>
      </div>
    </div>

    <Form
      as="div"
      class="bank-account--form"
      v-slot="{ meta }"
    >
      <div class="bank-account--fields">
        <input-with-label-validation
          rules="required|max:64"
          :name="$t('bankAccount')"
          :value="profile.bankAccountNumber"
          @input="($event) => { setProfileValues({ handle: 'bankAccountNumber', value: $event}) }"
          :label="$t('bankAccount')"
          :placeholder="$t('bankAccount')"
          :required="true"
        />
        <input-with-label-validation
          :rules="{max: 11, regex: /^[a-zA-Z0-9]+$/ }"
          :name="$t('swiftNumber')"
          :value="profile.swiftCode"
          @input="($event) => { setProfileValues({ handle: 'swiftCode', value: $event}) }"
          :label="$t('swiftNumber')"
          :placeholder="$t('swiftNumber')"
        />

        <div class="field __column">
          <div class="label required aligned">{{ $t('bankCountryCode')}}</div>
          <base-select
            :value="profile.bankCountryCode"
            :clearable="false"
            :disabled="true"
            :style="{ height: '40px', width: '100%'}"
          />
        </div>
        <input-with-label-validation
          rules="required|max:64"
          :name="$t('bankName')"
          :value="profile.bankName"
          @input="($event) => { setProfileValues({ handle: 'bankName', value: $event}) }"
          :label="$t('bankName')"
          :placeholder="$t('bankName')"
          :required="true"
        />
        <input-with-label-validation
          rules="required|max:8"
          :name="$t('bankCode')"
          :value="profile.bankCode"
          @input="($event) => { setProfileValues({ handle: 'bankCode', value: $event}) }"
          :label="$t('bankCode')"
          :placeholder="$t('bankCode')"
          :required="true"
        />
        <input-with-label-validation
          :rules="{ max: 16, required: profile.isCompany }"
          :name="$t('bankCnapsCode')"
          :value="profile.bankCnapsCode"
          @input="($event) => { setProfileValues({ handle: 'bankCnapsCode', value: $event}) }"
          :label="$t('bankCnapsCode')"
          :placeholder="$t('bankCnapsCode')"
          :required="profile.isCompany"
        />
      </div>

      <div class="divider __mt10"></div>

      <div class="button-wrap">
        <simple-button
          :value="$t('save')"
          :disabled="!meta.valid"
          @click="updatePaymentInformation"
        />
      </div>
    </Form>
  </div>
</template>

<script>
import { Form } from 'vee-validate'
import { mapGetters, useStore } from 'vuex'
import BaseSelect from 'smarket-ui/src/components/select/BaseSelect'
import SimpleButton from 'smarket-ui/src/components/buttons/SimpleButton'
import TooltipIcon from '@/components/ui/forms/TooltipIcon'
import { defaultBankCountryCode } from '@/mixins/defaultBankCountryCode'
import InputWithLabelValidation from './InputWithLabelValidation'

export default {
  name: 'BankAccount',
  components: {
    InputWithLabelValidation,
    TooltipIcon,
    SimpleButton,
    BaseSelect,
    Form,
  },
  mixins: [
    defaultBankCountryCode,
  ],
  setup () {
    const store = useStore()

    return {
      setProfileValues: (payload) => store.commit('setProfileValues', payload),
      updatePaymentInformation: () => store.dispatch('updatePaymentInformation'),
    }
  },
  computed: {
    ...mapGetters([
      'profile',
    ]),
  },
  created () {
    if (!this.profile.bankCountryCode) {
      this.setDefaultBankCountyCode()
    }
  },
}
</script>

<style scoped lang="scss">
.bank-account {
  &--fields {
    display: grid;
    grid-column-gap: 30px;
    grid-template-columns: 3fr 3fr;
  }
}

</style>
