<template>
  <div class="main-content">
    <base-bread-crumbs/>
    <div class="main-content__inner __profile">
      <div class="container">
        <UserProfile/>
      </div>
    </div>
    <div class="main-content__inner __profile">
      <div class="container">
        <BankAccount/>
      </div>
    </div>
  </div>
</template>

<script>
import BaseBreadCrumbs from '@/components/ui/layout/BaseBreadCrumbs'
import UserProfile from '@/components/ui/forms/UserProfile'
import BankAccount from '@/components/ui/forms/BankAccount'

export default {
  name: 'Profile',
  components: {
    BankAccount,
    UserProfile,
    BaseBreadCrumbs,
  },
}
</script>
