<template>
  <div class="user-profile">
    <div class="user-profile--contacts">
      <p>
        <svg-icon icon-name="user-lines"/>
        {{profile.contactName}}
      </p>
      <p>
        <svg-icon icon-name="mail-lines"/>
        {{email}}
      </p>
    </div>
    <div
      v-if="profileNotification"
      class="user-profile--notification"
      :class="profileNotificationClass"
    >
      <img
        v-if="profileNotificationIcon"
        class="user-profile--notification-icon"
        :src="require(`@/assets/${profileNotificationIcon}.svg`)"
        alt=""
      >
      <div class="user-profile--notification-text">
        <div v-if="profileRejected" class="user-profile--notification-title text-16-700">
          {{$t('rejectedAccount')}}
        </div>
        {{profileNotification}}
      </div>
    </div>
    <Form
      class="user-profile--form"
      v-slot="{ meta }"
    >
      <h2 class="h2">
        {{ $t('personalInfo')}}
        <TooltipIcon
          :title="$t('personalInformationTipTitle')"
          :text="$t('personalInformationTipText')"
        />
      </h2>
      <div class="user-profile--grid __two" v-if="isCompany">
        <input-with-label-validation
          rules="required"
          :name="$t('profileCompanyName')"
          :value="profile.companyName"
          :label="$t('profileCompanyName')"
          :placeholder="$t('profileCompanyName')"
          :required="true"
          :disabled="editIsDisabled"
          @input="($event) => { setProfileValues({ handle: 'companyName', value: $event}) }"
        />
        <div class="user-profile--field">
          <div class="label required aligned">{{ $t('shopBusinessType')}}</div>
          <base-select
            :value="userBusinessType"
            :options="shopBusinessTypes"
            :searchable="false"
            :clearable="false"
            :style="{ height: '50px'}"
            :disabled="editIsDisabled"
            @input="($event) => { setBusinessType($event) }"
          />
        </div>
        <input-with-label-validation
          name="businessTypeNameCn"
          rules="required"
          :style="{ display: 'none' }"
          :value="profile.businessTypeNameCn"
        />
      </div>

      <div class="user-profile--grid __two">
        <input-with-label-validation
          rules="required"
          :name="nameLabel"
          :value="profile.contactName"
          @input="($event) => { setProfileValues({ handle: 'contactName', value: $event}) }"
          :label="nameLabel"
          :placeholder="nameLabel"
          :required="true"
          :disabled="editIsDisabled"
        />
        <input-with-label-validation
          :rules="personalIdValidationRules"
          :name="personalIdLabel"
          :value="profile.personalId"
          @input="($event) => { setProfileValues({ handle: 'personalId', value: $event}) }"
          :label="personalIdLabel"
          :placeholder="personalIdLabel"
          :required="true"
          :disabled="editIsDisabled"
        />
      </div>
      <div class="user-profile--grid __two">
        <input-with-label-validation
          rules="required|email"
          :name="emailLabel"
          :value="profile.contactEmail"
          @input="($event) => { setProfileValues({ handle: 'contactEmail', value: $event}) }"
          :label="emailLabel"
          :placeholder="emailLabel"
          :required="true"
          :disabled="editIsDisabled"
        />
        <input-with-label-validation
          rules="required"
          :name="$t('contactPhone')"
          :value="profile.contactPhone"
          @input="($event) => { setProfileValues({ handle: 'contactPhone', value: $event}) }"
          :label="phoneLabel"
          :placeholder="phoneLabel"
          :required="true"
          :disabled="editIsDisabled"
        />
      </div>

      <div class="user-profile--grid __two" v-if="isCompany === false">
        <div class="user-profile--field">
          <div class="label required aligned">{{ $t('shopBusinessType')}}</div>
          <base-select
            :value="userBusinessType"
            :options="shopBusinessTypes"
            :searchable="false"
            :clearable="false"
            :disabled="editIsDisabled"
            :style="{ height: '50px'}"
            @input="($event) => { setBusinessType($event) }"
          />
        </div>
        <input-with-label-validation
          name="businessTypeNameCn"
          rules="required"
          :style="{ display: 'none' }"
          :value="profile.businessTypeNameCn"
        />
      </div>

      <h2 class="h2 __mt15">
        {{ $t('contactInfo')}}
        <TooltipIcon
          :title="$t('personalInformationTipTitle')"
          :text="$t('personalInformationTipText')"
        />
      </h2>
      <div class="user-profile--grid __two">
        <div class="user-profile--field">
          <div class="label required aligned">{{ $t('country')}}</div>
          <base-select
            :value="profile.country"
            :searchable="false"
            :clearable="false"
            :disabled="true"
            :style="{ height: '50px'}"
            @input="($event) => { setProfileValues({ handle: 'country', value: $event}) }"
          />
        </div>
        <input-with-label-validation
          name="country"
          rules="required"
          :style="{ display: 'none' }"
          :value="profile.country"
        />
        <input-with-label-validation
          rules="required"
          :name="$t('city')"
          :value="profile.city"
          @input="($event) => { setProfileValues({ handle: 'city', value: $event}) }"
          :label="$t('city')"
          :placeholder="$t('city')"
          :required="true"
          :disabled="editIsDisabled"
        />
      </div>
      <input-with-label-validation
        rules="required"
        :name="$t('address')"
        :value="profile.address"
        @input="($event) => { setProfileValues({ handle: 'address', value: $event}) }"
        :label="$t('address')"
        :placeholder="$t('address')"
        :required="true"
        :disabled="editIsDisabled"
      />

      <template v-if="editIsDisabled === false">
        <div class="divider"></div>
        <div class="button-wrap">
          <simple-button
            :value="$t('accountSubmit')"
            :disabled="!meta.valid"
            @click="validateAccount"
          />
        </div>
      </template>
    </Form>

    <SuccessModal
      :visible="showModal"
      :title="$t('accountDataSendTitle')"
      :description="$t('accountDataSendText')"
      @close="closeModal"
    />
  </div>
</template>

<script>
import { Form } from 'vee-validate'
import { mapGetters, mapState, useStore } from 'vuex'
import BaseSelect from 'smarket-ui/src/components/select/BaseSelect'
import SvgIcon from 'shared/src/components/SvgIcon'
import SimpleButton from 'smarket-ui/src/components/buttons/SimpleButton'
import {
  PROFILE_STATUS_NEW,
  PROFILE_STATUS_ENABLED,
  PROFILE_STATUS_PROCESSING,
  PROFILE_STATUS_APPROVED,
  PROFILE_STATUS_FAILED
} from '@/helpers/consts/profile-statuses'
import TooltipIcon from '@/components/ui/forms/TooltipIcon'
import SuccessModal from '@/components/ui/modals/SuccessModal'
import InputWithLabelValidation from './InputWithLabelValidation'

export default {
  name: 'UserProfile',
  components: {
    InputWithLabelValidation,
    SuccessModal,
    TooltipIcon,
    SimpleButton,
    SvgIcon,
    BaseSelect,
    Form,
  },
  data () {
    return {
      showModal: false,
    }
  },
  setup () {
    const store = useStore()

    return {
      setProfileValues: (payload) => store.commit('setProfileValues', payload),
      setBusinessType: (type) => store.commit('setBusinessType', type),
      getBusinessTypes: () => store.dispatch('getBusinessTypes'),
      updateProfile: () => store.dispatch('updateProfile'),
      verifyProfile: () => store.dispatch('verifyProfile'),
      showOverlay: () => store.commit('showOverlay'),
      hideOverlay: () => store.commit('hideOverlay'),
      showHintNotification: (notification) => store.commit('showHintNotification', notification),
      getProfile: () => store.dispatch('getProfile'),
    }
  },
  computed: {
    ...mapGetters([
      'profile',
      'businessTypes',
    ]),
    ...mapState({
      email: state => state.user.email,
    }),
    isCompany () {
      return this.profile.isCompany
    },
    profileRejected () {
      return this.profile.status === PROFILE_STATUS_FAILED
    },
    profileNotification () {
      switch (this.profile.status) {
        case PROFILE_STATUS_NEW:
        case PROFILE_STATUS_ENABLED:
          return this.$t('newAccountMessage')
        case PROFILE_STATUS_PROCESSING:
          return this.$t('awaitingAccountMessage')
        case PROFILE_STATUS_FAILED:
          return this.profile.llpMessage
        default:
          return null
      }
    },
    profileNotificationIcon () {
      switch (this.profile.status) {
        case PROFILE_STATUS_PROCESSING:
          return 'clock'
        case PROFILE_STATUS_FAILED:
          return 'error'
        default:
          return null
      }
    },
    profileNotificationClass () {
      switch (this.profile.status) {
        case PROFILE_STATUS_PROCESSING:
          return 'user-profile--notification__processing'
        case PROFILE_STATUS_FAILED:
          return 'user-profile--notification__failed'
        default:
          return ''
      }
    },
    editIsDisabled () {
      return this.profile.status === PROFILE_STATUS_PROCESSING || this.profile.status === PROFILE_STATUS_APPROVED
    },
    nameLabel () {
      return this.isCompany ? this.$t('profileContactNameCompany') : this.$t('profileContactName')
    },
    personalIdLabel () {
      return this.isCompany ? this.$t('profileCompanyId') : this.$t('personalId')
    },
    personalIdValidationRules () {
      return this.isCompany ? { required: true, length: 18 } : { required: true, regex: /^[1-9]\d{5}(18|19|([23]\d))\d{2}((0[1-9])|(10|11|12))(([0-2][1-9])|10|20|30|31)\d{3}[0-9Xx]$/i }
    },
    emailLabel () {
      return this.isCompany ? this.$t('contactEmailCompany') : this.$t('contactEmail')
    },
    phoneLabel () {
      return this.isCompany ? this.$t('contactPhoneCompany') : this.$t('contactPhone')
    },
    shopBusinessTypes () {
      return JSON.parse(JSON.stringify(this.businessTypes)).map(item => {
        item.label = item.nameCn || ''
        return item
      })
    },
    userBusinessType () {
      return {
        nameCn: this.profile.businessTypeNameCn,
        label: this.profile.businessTypeNameCn,
        code: this.profile.businessTypeCode,
      }
    },
  },
  methods: {
    async validateAccount () {
      try {
        await this.updateProfile()
        this.showOverlay()
        this.showModal = true
        const approveResult = await this.verifyProfile()

        switch (approveResult.status) {
          case PROFILE_STATUS_FAILED:
            await this.afterApproval(
              'ERROR',
              this.$t('accountRejectedTitle'),
              this.$t('accountRejectedText'),
            )
            break
          case PROFILE_STATUS_APPROVED:
            await this.afterApproval(
              'SUCCESS',
              this.$t('accountVerifiedTitle'),
              this.$t('accountVerifiedText'),
            )
            break
          case PROFILE_STATUS_PROCESSING:
            await this.afterApproval(
              'SUCCESS',
              this.$t('accountDataSendTitle'),
              this.$t('accountDataSendText'),
            )
            break
          default:
            await this.afterApproval(
              'ERROR',
              this.$t('copyErrorTitle'),
            )
            break
        }
      } catch (e) {
        this.showHintNotification({
          type: 'ERROR',
          title: this.$t('copyErrorTitle'),
        })
      }
    },

    async afterApproval (type, title, description) {
      this.showHintNotification({
        type,
        title,
        description,
      })
      this.closeModal()
      await this.getProfile()
    },

    closeModal () {
      this.hideOverlay()
      this.showModal = false
    },
  },
  async mounted () {
    await this.getBusinessTypes()
  },
}
</script>

<style scoped lang="scss">
.user-profile {
  &--contacts {
    padding: 20px;
    background-color: $color-background;
    margin-bottom: 40px;
    border-radius: 16px;

    > p {
      display: flex;
      align-items: center;
      color: #000;
      font-size: 18px;
      font-weight: 800;

      &:not(:last-child) {
        margin-bottom: 15px;
      }
    }
  }

  &--field {
    position: relative;
    padding-bottom: 25px;
  }
  &--grid {
    display: grid;
    grid-column-gap: 30px;

    &.__two {
      grid-template-columns: 3fr 3fr;
    }

    &.__three {
      grid-template-columns: 2fr 2fr 2fr;
    }
  }
}
</style>
